import React, { useState, useEffect, useRef } from 'react';
import { useData } from '../contexts/DataContext';
import { openFileOrFiles, saveFile } from '../helpers/FileSystem';

interface SignConfiguratorProps {
  children: React.ReactNode;
}

const SignConfigurator: React.FC<SignConfiguratorProps> = ({ children }) => {
  const { height, updateContext, resetContext } = useData();
  const [items, setItems] = useState<any[]>([]);
  const [textareaValue, setTextareaValue] = useState<string>('');
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);
  const [playIndex, setPlayIndex] = useState<number | null>(null);
  const [filterText, setFilterText] = useState<string>(''); // State for filter text

  const [isScaled, setIsScaled] = useState(true); // State to manage the scale toggle
  const [isTimeFixEnabled, setIsTimeFixEnabled] = useState(true); // State to manage the timeFix toggle
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]); // Array to store refs for list items
  const [isError, setIsError] = useState(false); // State to track error status

  const handleScaleToggle = () => {
    setIsScaled((prev) => !prev); // Toggle the scale state
  };
  const handleTimeFixToggle = () => {
    setIsTimeFixEnabled((prev) => !prev); // Toggle the time fix state
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value); // Update filter text state
  };

  const handleClearFilter = () => {
    setFilterText(''); // Clear filter text
  };

  const filteredItems = items.filter((item) =>
    item._?.toLowerCase().includes(filterText.toLowerCase()) // Filter items based on filter text
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const configuratorElement = document.getElementById('configurator');
      if (configuratorElement && !configuratorElement.contains(event.target as Node)) {
        setSelectedItemIndex(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(e.target.value);
    try {
      JSON.parse(e.target.value); //try to parse
      setIsError(false)
    } catch (error) {
      setIsError(true)
    }
  };

  const addItem = () => {
    try {
      const newItem = JSON.parse(textareaValue);
      setItems([...items, newItem]);
      selectItem(items.length);
      setTextareaValue(JSON.stringify(newItem, null, 4))
      if(newItem?.type==='BEACON')
        runUpdateContext(newItem)
      else
        resetContext()
    } catch (error) {
      console.error("Invalid JSON:", error);
    }
  };

  const addTimer = () => {
    const timer = {  _:'Sleep 1s', type: 'timer', ms: 1000 };
    setItems([...items, timer]);
    selectItem(items.length);
    setTextareaValue(JSON.stringify(timer, null, 4))
  };

  const addAdvance = () => {
    const timer = {  _:'Sleep 1 Advance', type: 'advance', count: 1.0 };
    setItems([...items, timer]);
    selectItem(items.length);
    setTextareaValue(JSON.stringify(timer, null, 4))
  };

  const addLoadingState = () => {
    const loadingState = { _:'Reset', type: 'loading' };
    setItems([...items, loadingState]);
    selectItem(items.length);
    setTextareaValue(JSON.stringify(loadingState, null, 4))
  };

  const selectItem = (index: number|null) => {
    setSelectedItemIndex(index);
    setIsError(false)

    if(index===null){
      setTextareaValue('')
      resetContext()
      return
    }

    setTextareaValue(JSON.stringify(items[index], null, 4));

    if(items[index]?.type==='BEACON')
      runUpdateContext(items[index])
    else
      resetContext()

    // Scroll the selected item into view
    setTimeout(()=> {
      if (itemRefs.current[index]) {
        itemRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    },0)
  };

  const deleteItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
    if (index === selectedItemIndex) {
      selectItem(null); // Reset selection if the deleted item was selected
      setTextareaValue('');
    }
  };

  const moveItem = (index: number, direction: 'up' | 'down') => {
    if ((direction === 'up' && index > 0) || (direction === 'down' && index < items.length - 1)) {
      const newItems = [...items];
      const item = newItems.splice(index, 1)[0];
      newItems.splice(direction === 'up' ? index - 1 : index + 1, 0, item);
      setItems(newItems);
      selectItem(null);
      setTextareaValue('')
    }
  };

  const saveItem = () => {
    if (selectedItemIndex !== null) {
      try {
        const updatedItem = JSON.parse(textareaValue);
        const newItems = [...items];
        newItems[selectedItemIndex] = updatedItem;
        setItems(newItems);
        if(updatedItem?.type==='BEACON')
          runUpdateContext(updatedItem)
      } catch (error) {
        console.error("Invalid JSON during save:", error);
      }
    }
  };
  const onSave = async () => {
    const blob = new Blob([JSON.stringify(items, null, 2)], { type: 'application/json' });
    const file = await saveFile(blob,'myTest.json')
    if(!file) return
  };
  const fileTypes = [
    {
      description: "JSON Files",
      accept: {
        "application/json": [".json"],
      },
    },
  ]
  const onOpen = async () => {
    try {
      let file = await openFileOrFiles(false,true,fileTypes)
      if (!file) return;

      const dataText = await file.text()
      const data = JSON.parse(dataText);
      setItems(data);
      handleClearFilter()
      selectItem(null)
    } catch (error) {
      console.error("Error onOpen:", error);
    }
  };

  const onOverwriteFromClipboard = async() => {
    try {
      if(navigator.clipboard){
        await navigator.clipboard.readText()
          .then(dataText=>{
            const data = JSON.parse(dataText);
            setItems(data);
            handleClearFilter()
            selectItem(null)
            console.log("Text read from clipboard: ", dataText);
          }).catch(err => {
            console.error("Failed to read text from clipboard: ", err);
          });
        return
      }
      const updatedItems = JSON.parse(textareaValue);
      setItems(updatedItems);
      selectItem(null)
    } catch (error) {
      console.error("Error onOverwriteFromClipboard:", error);
    }
  }

  const sleep=(ms: number)=>{
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const onPlay = async () => {
    try{
      setSelectedItemIndex(null)
      for(let i=0; i<items.length; i++){
        const r = items[i];
        setPlayIndex(i)
        switch(r.type){
          case 'advance':
            await sleep(r.count*(import.meta.env.VITE_SCREEN_ROTATION_INTERVAL || 1000))
            break;
          case 'timer':
            await sleep(r.ms)
            break;
          case 'loading':
            resetContext()
            await sleep(250)
            break;
          case 'BEACON':
            runUpdateContext(r)
            break;
        }
      }
    }catch(e){
      console.log(e)
    }finally{
      setPlayIndex(null)
    }
  }

  const runUpdateContext = (r: any) =>{
    if(isTimeFixEnabled && r?.sections?.[0]?.trains?.length > 0){
      return updateContext(runTimeFix(r))
    }
    return updateContext(r)
  }

  const runTimeFix = (r: any) =>{
    const currentTime = new Date().getTime()/1000
    const stateTime = r.timestamp
    const adjustment = currentTime-stateTime
    const fixedTrains = r.sections[0].trains.map((t:any)=>({...t, est_time:t.est_time+adjustment}))
    return {
      ...r,
      sections:[
        {trains: fixedTrains}
      ]
    }
  }

  const buttonStyle = {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '5px 10px',
    margin: '8px 8px 8px 0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontFamily: 'sans-serif'
  };

  const listItemStyle = (index: number) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    margin: '5px 0',
    backgroundColor: playIndex!==null&&index<=playIndex ? '#87A96B': index === selectedItemIndex ? 'gold' : '#f9f9f9',
    border: '1px solid #ddd',
    maxHeight: 80,
    overflow: 'hidden'
  });

  const arrowButtonStyle = {
    backgroundColor: '#ccc',
    cursor: 'pointer',
    margin: '5px',
    width: '20px',
    height: '20px',
    lineHeight: '1'
  };

  const deleteButtonStyle = {
    backgroundColor: 'red',
    lineHeight: '1',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    margin: '5px',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
  };

  return (
    <div id="configurator" style={{ backgroundColor: '#e3f2fd', display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px', height: '100vh' }}>
      <style>{`
    button:not(:disabled) {
      transition: filter 0.3s ease; /* Smooth transition for brightness */
    }

    button:not(:disabled):hover {
      cursor: pointer; /* Change cursor to pointer */
      filter: brightness(1.2); /* Increase brightness on hover */
    }
      `}</style>
      <div>
        <button style={{...buttonStyle,backgroundColor:'#4fc3f7'}} onClick={onOpen}>Open</button>
        <button style={{...buttonStyle,backgroundColor:'#4fc3f7'}} onClick={onOverwriteFromClipboard}>Paste</button>
        <button style={{...buttonStyle,backgroundColor:'#1565c0'}} onClick={onSave}>Save</button>
        <button style={{...buttonStyle, backgroundColor:(playIndex !== null?'lightgray':'#2e7d32')}} 
          onClick={onPlay}
          disabled={playIndex !== null}>Play</button>
      </div>
      <div>
        <label>
          <input type="checkbox" checked={!isScaled} onChange={handleScaleToggle} />
          &nbsp;Toggle Full Scale
        </label>
        <br />
        <label>
          <input type="checkbox" checked={isTimeFixEnabled} onChange={handleTimeFixToggle} />
          &nbsp;Recalc TimeStamps Enabled
        </label>
      </div>
      <div style={{ 
          height: (height===-1?480:height) / 4,
          minHeight: 120,
          width: 1920 / 4 }}>
        <div style={{ 
          height: (height===-1?480:height),
          width: 1920, 
          transform: isScaled ? 'scale(0.25)' : 'scale(1)', // Conditional transform based on the checkbox
          transformOrigin: 'top left',
          border: 'none'
          }}>
          {children}
        </div>
      </div>
      <div style={{display:'flex', flexDirection:'row', gap: 20, flex: 1, overflow: 'hidden', fontFamily: 'sans-serif'}}>
        <div style={{minWidth:300, maxWidth: '50%', overflowY: 'scroll', height: '100%', background: 'lightblue'}}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', padding: '5px' }}>
            <input
              type="text"
              placeholder="Filter items..."
              value={filterText}
              onChange={handleFilterChange}
              style={{ flex: 1, padding: '5px' }}
            />
            <button onClick={handleClearFilter} style={{ ...buttonStyle, padding: '5px', backgroundColor: 'red', color: 'white', margin: '0' }}>
              &times;
            </button>
          </div>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {filteredItems.length > 0 ? filteredItems.map((item, index) => (
              <li 
                key={index} 
                ref={(el) => (itemRefs.current[index] = el)} // Assign each item a ref
                style={listItemStyle(index)} 
                onClick={() => selectItem(index)}>
                <div style={{width: 120, minWidth:120, height: 30}}>
                  <div style={{display:'inline-block',
                    height: 18,
                    width: 18,
                    verticalAlign: 'middle'}}>
                      <input type='checkbox' />
                  </div>
                  <div style={{display:'inline-block'}}><button style={arrowButtonStyle} onClick={(e) => { e.stopPropagation(); moveItem(index, 'up'); }}>&uarr;</button></div>
                  <div style={{display:'inline-block'}}><button style={arrowButtonStyle} onClick={(e) => { e.stopPropagation(); moveItem(index, 'down'); }}>&darr;</button></div>
                  <div style={{display:'inline-block'}}><button style={deleteButtonStyle} onClick={(e) => { e.stopPropagation(); deleteItem(index); }}>&times;</button></div>
                </div>
                <div style={{flex:'1', maxHeight: 'inherit'}}>
                  {item._ !== undefined? item._ : JSON.stringify(item).substring(0,100)}
                </div>
              </li>
            )) : items.length>0 ? (
              <li style={{ textAlign: 'center', padding: '20px', color: 'red' }}>No items match your filter.</li>
            ) : (
              <li style={{ textAlign: 'center', padding: '20px', color: 'grey' }}>Open a file or add records.</li>
            )}
          </ul>
        </div>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1
        }}>
          <div>
            <button style={{...buttonStyle,backgroundColor:'#ab47bc'}} onClick={addItem}>➕ Data</button>
            <button style={{...buttonStyle,backgroundColor:'#ab47bc'}} onClick={addTimer}>➕ Timer</button>
            <button style={{...buttonStyle,backgroundColor:'#ab47bc'}} onClick={addAdvance}>➕ Advance</button>
            <button style={{...buttonStyle,backgroundColor:'#ab47bc'}} onClick={addLoadingState}>➕ Loading State</button>
            <button style={{...buttonStyle, backgroundColor:((selectedItemIndex === null|| isError)?'lightgray':'#2e7d32')}} 
              onClick={saveItem} 
              disabled={selectedItemIndex === null || isError}>Update Record</button>
          </div>
          <label>Data (JSON):</label>
          <div style={{
              flex: 1,
          }}>
              <textarea
                value={textareaValue}
                onChange={handleTextareaChange}
                style={{ 
                  border: isError ? '5px solid red' : '5px solid #ccc',
                  width: '100%', 
                  height: '100%',
                  padding: '10px', 
                  boxSizing: 'border-box',
                  fontFamily: 'monospace',
                  fontSize: '1em'
                }}
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignConfigurator;