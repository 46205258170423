import React from "react";
import type { LineProps } from "../types"

import Line1 from "../shared/icons/1.svg";
import Line2 from "../shared/icons/2.svg";
import Line3 from "../shared/icons/3.svg";
import Line4 from "../shared/icons/4.svg";
import Line5 from "../shared/icons/5.svg";
import Line6 from "../shared/icons/6.svg";
import Line6X from "../shared/icons/6X.svg";
import Line7 from "../shared/icons/7.svg";
import Line7X from "../shared/icons/7X.svg";
import LineA from "../shared/icons/A.svg";
import LineB from "../shared/icons/B.svg";
import LineC from "../shared/icons/C.svg";
import LineD from "../shared/icons/D.svg";
import LineE from "../shared/icons/E.svg";
import LineF from "../shared/icons/F.svg";
import LineFX from "../shared/icons/FX.svg";
import LineG from "../shared/icons/G.svg";
import LineJ from "../shared/icons/J.svg";
import LineL from "../shared/icons/L.svg";
import LineM from "../shared/icons/M.svg";
import LineN from "../shared/icons/N.svg";
import LineQ from "../shared/icons/Q.svg";
import LineR from "../shared/icons/R.svg";
import LineS from "../shared/icons/S.svg";
import LineW from "../shared/icons/W.svg";
import LineZ from "../shared/icons/Z.svg";
import MTA from "../shared/icons/MTA.svg";
import SIR from "../shared/icons/SIR.svg";
import JFK from "../shared/icons/JFK.svg";


export const icons: { [key: string]: {icon:string, text?:string} } = {
  "1": { icon: Line1 },
  "2": { icon: Line2 },
  "3": { icon: Line3 },
  "4": { icon: Line4 },
  "5": { icon: Line5 },
  "6": { icon: Line6 },
  "6X": { icon: Line6X },
  "7": { icon: Line7 },
  "7X": { icon: Line7X },
  "A": { icon: LineA },
  "B": { icon: LineB },
  "C": { icon: LineC },
  "D": { icon: LineD },
  "E": { icon: LineE },
  "F": { icon: LineF },
  "FX": { icon: LineFX },
  "G": { icon: LineG },
  "J": { icon: LineJ },
  "L": { icon: LineL },
  "M": { icon: LineM },
  "N": { icon: LineN },
  "Q": { icon: LineQ },
  "R": { icon: LineR },
  "S": { icon: LineS },
  "GS": { icon: LineS }, //alias
  "FS": { icon: LineS }, //alias
  "H": { icon: LineS }, //alias
  "W": { icon: LineW },
  "Z": { icon: LineZ },
  "SIR": { icon: SIR },
  "SI": { icon: SIR }, //alias
  "MTA": { icon: MTA },
  "JFK": { icon: JFK },
  "AIRPLANE ICON": { icon: JFK },
  "AIRPLANE": { icon: JFK },
  "PLANE": { icon: JFK }
};

export const isValidIcon = (line: string) => {
    return line.toUpperCase() in icons;
}

export const Icon: React.FC<LineProps> = ({ line }) => {
    const normalizedLine = line.toUpperCase();

    if (!isValidIcon(normalizedLine)) return null;

    const iconRecord = icons[normalizedLine];

    return (
      <span className='bullet'>
        <span className='imgContainer'>
          <img src={iconRecord.icon} 
            alt={`Line ${line}`}
            style={{
              height: '100%',
              width: '100%'
            }} />
        </span>
        {iconRecord.text !== undefined ? (
          <>
            &nbsp;<span>{iconRecord.text}</span>
          </>
        ) : null}      
      </span>
    );
};