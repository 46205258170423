import { useEffect } from 'react';
import { SignMode } from '../types';

interface SolariWatchdogProps {
  mode: SignMode;
}

const SolariWatchdog: React.FC<SolariWatchdogProps> = ({ mode }) => {
  useEffect(() => {
    const handleMessage = (ev: MessageEvent) => {
      console.log('Watchdog called handleMessage:',ev.data)
      // optional check origin
      if (ev.data.type === 'watchdog') {
        // Application logic to respond in case all is OK.
        const isOK = mode !== SignMode.Fallback
        console.log('Watchdog:' + (isOK?'OK':"not ok") )
        if(isOK){
          console.log('Watchdog responded: Before')
          //@ts-ignore
          ev.source?.postMessage(ev.data, '*')
          console.log('Watchdog responded: After')
        }
        // what to send when not ok?
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [mode]);

  return null; // No visible output for this component
};

export default SolariWatchdog;
