import { useEffect, useState } from 'react';

function VersionChecker() {
  const pollInterval = parseInt(import.meta.env.VITE_COMMIT_REF_POLL_INTERVAL, 10) || 3600000;  // Default to 1 hour
  const environment = import.meta.env.VITE_ENVIRONMENT;
  const currentCommitRef = import.meta.env.VITE_COMMIT_REF || 'local';

  const [successCount,setSuccessCount] = useState<number>(0);

  useEffect(() => {
    // Skip the check if the environment is local
    if (environment === 'local') {
      console.log('Version check is disabled in the local environment.');
      return;
    }
    if (currentCommitRef === 'local') {
      console.log('Version check is disabled, commitRef is invalid.');
      return;
    }

    // Function to fetch the current COMMIT_REF
    const fetchCommitRef = async () => {
      try {
        const response = await fetch('/version.json');
        const data = await response.json();
        return data.commitRef;
      } catch (error) {
        console.error('Failed to fetch version.json:', error);
        return null;  // In case of error, return null to avoid unintended reloads
      }
    };

    // Only run the interval if pollInterval is not -1
    if (pollInterval !== -1) {
      const intervalId = setInterval(async () => {
        const newCommitRef = await fetchCommitRef();
        if (newCommitRef && typeof newCommitRef === "string" && newCommitRef.length>0){
          setSuccessCount(prev => prev + 1)

          if(newCommitRef !== currentCommitRef) {
            console.log('New COMMIT_REF detected, reloading the page.', {'from':currentCommitRef, 'to':newCommitRef});
            window.location.reload();
          }
        }
      }, pollInterval);

      // Cleanup the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [pollInterval, environment, currentCommitRef]);

  useEffect(()=>{
    const currentHour = new Date().getHours();
    if(successCount>=23 && [11,12].includes(currentHour)){ //Between 11AM and 1pm
      console.log('Success Count Triggered Refresh', successCount, new Date());
      window.location.reload();
    }

  }, [successCount])

  return null;  // This component does not render anything
}

export default VersionChecker;
