import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface RegisteredFunction {
  id: string;
  fn: () => Promise<void>;
  interval: number;
  delay: number;
}

interface TimeContextType {
  registerFunction: (id: string, fn: () => Promise<void>, intervalMs?: number, delayMs?: number) => string;
  unregisterFunction: (id: string) => void;
}

const TimeContext = createContext<TimeContextType | undefined>(undefined);

export const TimeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [functionsToRun, setFunctionsToRun] = useState<Map<string, RegisteredFunction>>(new Map());

  useEffect(() => {
    const worker = createWorkerFromBlob();

    worker.onmessage = async (event) => {
      const { type, time: tickTime } = event.data;

      if (type === 'tick') {
        const promises = [...functionsToRun.values()].map(({ id, fn, interval, delay }) => {
          if ( (interval % 100) !== 0) {
              console.warn(`Function with ID ${id} has an invalid interval (${interval} ms). It must be a multiple of 100 ms.`);
              return Promise.resolve(); // Return a resolved promise to skip invalid intervals
          }

          if ( (tickTime % interval) === 0) {
            return new Promise((resolve) => {
              setTimeout(resolve, delay);
            }).then(() => {
              const now = new Date().getTime()
              console.log({ time:tickTime + delay, id, actualTime:now, interval })
              fn()
            });
          }
          return Promise.resolve();
        });

        try {
          await Promise.all(promises); // Await all promises in parallel
        } catch (error) {
          console.error(`Error executing one or more functions:`, error);
        }
      }
    };

    // Cleanup the worker on unmount
    return () => {
      worker.terminate();
    };
  }, [functionsToRun]);

  const registerFunction = useCallback((id: string, fn: () => Promise<void>, intervalMs: number = 1000, delayMs: number = 0): string => {
    setFunctionsToRun(prevMap => {
      // console.log('add',id)
      const newMap = new Map(prevMap);
      newMap.set(id, { id, fn, interval: intervalMs, delay: delayMs });
      return newMap;
    });
    return id;
  }, []);

  const unregisterFunction = useCallback((id: string) => {
    setFunctionsToRun(prevMap => {
      // console.log('remove',id)
      const newMap = new Map(prevMap);
      newMap.delete(id);
      return newMap;
    });
  }, []);

  return (
  <TimeContext.Provider value={{ registerFunction, unregisterFunction }}>
    {children}
  </TimeContext.Provider>
  );
};

export const useTimeContext = (): TimeContextType => {
  const context = useContext(TimeContext);
  if (!context) {
    throw new Error('useTimeContext must be used within a TimeProvider');
  }
  return context;
};


const createWorkerFromBlob = () => {
  // Convert the function definition into a string
  const workerScript = `
function scheduleNextTick() {
  const now = Date.now();
  const nextTick = now - (now % 100) + 100;
  const msUntilNextTick = nextTick - now;

  setTimeout(() => {
    postMessage({ type: 'tick', time: nextTick });
    scheduleNextTick();
  }, msUntilNextTick);
};
    scheduleNextTick(); // Call the function inside the worker script
  `;
  const blob = new Blob([workerScript], { type: 'application/javascript' });
  return new Worker(URL.createObjectURL(blob));
};